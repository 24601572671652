<template>
    <!-- <vx-card> -->
        <vs-tabs>
            <vs-tab label="Open">
                <open></open>
            </vs-tab>
            <vs-tab label="Invalid">
                <div class="tab-text">
                    <invalid></invalid>
                </div>
            </vs-tab>
            <vs-tab label="Valid">
                <div class="tab-text">
                    <valid></valid>
                </div>
            </vs-tab>
        </vs-tabs>
    <!-- </vx-card> -->
</template>
<script>
    import Open from "./open";
    import Valid from "./valid";
    import Invalid from "./invalid";
    export default {
        components: {
            Open,
            Valid,
            Invalid,
        },
    };
</script>