<style>
.input-readonly > div > .vs-inputx {
  background-color: #e6e6e6;
}

.bg-highlight > td {
  background-color: #f80808;
  color: #e6e6e6;
}
</style>
<template>
  <div class="vx-col w-5/6 ml-auto mr-auto">
    <span>
      <h4 class="mb-5">Supplier Invoice</h4>
    </span>
    
    <div class="vx-row mb-2">
      <div class="vx-col w-1/2">
        <label class="vs-input--label">Transaction Date</label>
        <datepicker
          class="w-full"
          v-validate="'required'"
          :inline="false"
          name="Transaction Date"
          v-model="transaction_date"
          placeholder="Transaction date"
          format="MMMM dd, yyyy"
          label="Transaction Date"
          :disabledDates="disabledAfterToday"
        ></datepicker>
        <span
        class="text-danger text-sm"
        v-if="transaction_date == null && status_submit"
        >This field is required</span
      >
      </div>
    </div>
    <div class="vx-row mt-6">
      <div class="vx-col w-full">
        <vs-button class="mr-3 mb-2" @click="validate">Validate</vs-button>
      </div>
    </div>
    <vs-divider style="width: 100%; margin-left: 0%">Supplier Data</vs-divider>
    <div class="vx-row mb-2">
      <div class="vx-col w-1/3">
        <vs-input
          class="w-full input-readonly"
          label="Supplier"
          name="Supplier"
          v-model="vendor"
          readonly
        />
      </div>
      <div class="vx-col w-1/3">
        <vs-input
          class="w-full input-readonly"
          label="Supplier PIC"
          name="Supplier PIC"
          v-model="supplier_contact_name"
          readonly
        />
      </div>
      <div class="vx-col w-1/3">
        <vs-input
          class="w-full input-readonly"
          label="PIC Phone Number"
          name="PIC Phone Number"
          v-model="supplier_phone"
          readonly
        />
      </div>
    </div>
    <div class="vx-row mb-2" style="z-index: 99999 !important">
      <div class="vx-col w-full">
        <vs-input
          class="w-full input-readonly"
          label="Supplier Bank Name"
          name="Supplier Bank Name"
          v-model="table.bank_name"
          readonly
        />
      </div>
    </div>
    <div class="vx-row mb-2">
      <div class="vx-col w-full">
        <vs-input
          class="w-full input-readonly"
          label="Supplier Tax Number"
          name="Supplier Tax Number"
          v-model="tr_number"
          placeholder="Supplier Tax Number"
          readonly
        />
      </div>
    </div>
    <div class="vx-row mb-2">
      <div class="vx-col w-full">
        <vs-input
          class="w-full input-readonly"
          label="Supplier Tax Date"
          name="Supplier Tax Date"
          v-model="tr_date"
          placeholder="Supplier Tax Date"
          readonly
        />
      </div>
    </div>
    <vs-divider style="width: 100%; margin-left: 0%">PO / GR Data</vs-divider>
    <div class="vx-row mb-2">
      <div class="vx-col w-full">
        <vs-input
          class="w-full input-readonly"
          label="Warehouse"
          name="Warehouse"
          v-model="warehouseName"
          readonly
        />
      </div>
    </div>
    <div class="vx-row mb-2">
      <div class="vx-col w-full">
        <vs-input
          class="w-full input-readonly"
          label="PO Code"
          name="PO Code"
          v-model="poCode"
          readonly
        />
      </div>
    </div>
    <div class="vx-row mb-2">
      <div class="vx-col w-full">
        <vs-input
          class="w-full input-readonly"
          label="PO Date"
          name="PO Date"
          v-model="po_date"
          readonly
        />
      </div>
    </div>
    <div class="vx-row mb-2">
      <div class="vx-col w-full">
        <vs-input
          class="w-full input-readonly"
          label="GR Code"
          name="GR Code"
          v-model="gr_codes"
          readonly
        />
      </div>
    </div>
    <div class="vx-row mb-2">
      <div class="vx-col w-full">
        <vs-input
          class="w-full input-readonly"
          label="Proof of GR Date"
          name="Proof of GR Date"
          v-model="gr_dates"
          readonly
        />
      </div>
    </div>
    <vs-divider style="width: 100%; margin-left: 0%">Invoice Data</vs-divider>
    <div class="vx-row mb-2">
      <div class="vx-col w-full">
        <vs-input
          v-validate="'required'"
          class="w-full input-readonly"
          label="Invoice Number"
          name="Invoice Number"
          v-model="table.inv_code"
          placeholder="Invoice Number"
          readonly
        />
      </div>
    </div>
    <div class="vx-row mb-2">
      <div class="vx-col w-full">
        <vs-input
          class="w-full input-readonly"
          label="Invoice Date"
          name="Invoice Date"
          v-model="table.inv_date"
          readonly
        />
      </div>
    </div>
    <div v-if="createdDate != 'January 1, 0001'" class="vx-col w-full mb-2">
      <vs-input
        class="w-full input-readonly"
        label="Created Date"
        name="Created Date"
        v-model="createdDate"
        readonly
      />
    </div>
    <div class="vx-row mb-2" style="z-index: 99999 !important">
      <div class="vx-col w-full">
        <vs-input
          class="w-full input-readonly"
          label="Payment Term"
          name="Payment Term"
          v-model="table.payment_term_name"
          readonly
        />
      </div>
    </div>
    <div v-if="dueDate != 'January 1, 0001'" class="vx-col w-full mb-2">
      <vs-input
        class="w-full input-readonly"
        label="Due Date"
        name="Due Date"
        v-model="dueDate"
        readonly
      />
    </div>
    <div class="vx-row mb-2">
      <div class="vx-col w-full">
        <vs-input
          class="w-full input-readonly"
          label="Currency Code"
          name="Currency Code"
          v-model="table.currency_code"
          readonly
        />
      </div>
    </div>
    <!-- <div class="vx-row mb-2 ml-1">
      <h5>Line Item</h5>
    </div> -->
    <vs-divider style="width: 100%; margin-left: 0%"><h5>Line Item</h5></vs-divider>
    <vs-table stripe border description :sst="true" :data="table.data">
      <template slot="thead">
        <vs-th>Item</vs-th>
        <vs-th v-if="project">Progress</vs-th>
        <vs-th v-else>Quantity</vs-th>
        <vs-th>Price</vs-th>
        <vs-th>Discount</vs-th>
        <vs-th>Net</vs-th>
        <vs-th>Delta</vs-th>
      </template>

      <template slot-scope="{ data }">
        <vs-tr
          :data="tr"
          :key="indextr"
          v-for="(tr, indextr) in data"
          :class="data[indextr].class"
        >
          <vs-td :data="data[indextr].item_name">
            <div class="vx-row ml-0">
              <span class="w-5/5">{{ data[indextr].item_name }}</span>
            </div>
            <div class="vx-row ml-0">
              <span v-if="!project" class="w-5/5">{{ data[indextr].item_unit }}</span>
            </div>
          </vs-td>
          <vs-td v-if="project">
            PO : {{ data[indextr].progress_po }} %<br />
            GR : {{ data[indextr].progress_bast }} %<br />
          </vs-td>
          <vs-td v-else>
            PO : {{ data[indextr].qty_po }} {{ data[indextr].item_unit }}<br />
            Received : {{ data[indextr].qty_received }} {{ data[indextr].item_unit }}<br />
            Invoice : {{ data[indextr].quantity }} {{ data[indextr].item_unit }}
          </vs-td>
          <vs-td>
            <div v-if="project" class="vx-row ml-0">
              PO : {{ data[indextr].price_po_progress }}<br />
              GR : {{ data[indextr].price_bast_progress }}<br />
              Invoice : {{ data[indextr].price }}
            </div>
            <div v-else class="vx-row ml-0">
              PO : {{ data[indextr].price_po }}<br />
              Received : {{ data[indextr].price_po }}<br />
              Invoice : {{ data[indextr].price }}
            </div>
          </vs-td>
          <vs-td>
            <div v-if="project" class="vx-row ml-0">
              PO : {{ data[indextr].discount_po }}<br />
              GR : {{ data[indextr].discount_po }}<br />
              Invoice : {{ data[indextr].discount_inv }}
            </div>
            <div v-else class="vx-row ml-0">
              PO : {{ data[indextr].discount_po }}<br />
              Received : {{ data[indextr].discount_po }}<br />
              Invoice : {{ data[indextr].discount_inv }}
            </div>
          </vs-td>
          <vs-td>
            <div v-if="project" class="vx-row ml-0">
              PO : {{ data[indextr].net_po_progress }}<br />
              GR : {{ data[indextr].net_bast_progress }}<br />
              Invoice : {{ data[indextr].net_inv }}
            </div>
            <div v-else class="vx-row ml-0">
              PO : {{ data[indextr].net_po }}<br />
              Received : {{ data[indextr].net_received }}<br />
              Invoice : {{ data[indextr].net_inv }}
            </div>
          </vs-td>
          <vs-td>
            <div v-if="project" class="vx-row ml-0">
              Progress : {{ data[indextr].delta_progress }} %<br />
              Price : {{ data[indextr].delta_price_progress }}<br />
              Discount : {{ data[indextr].delta_discount }}<br />
              Net : {{ data[indextr].delta_net_progress }}
            </div>
            <div v-else class="vx-row ml-0">
              Qty : {{ data[indextr].delta_qty }}<br />
              Price : {{ data[indextr].delta_price }}<br />
              Discount : {{ data[indextr].delta_discount }}<br />
              <!-- Net : {{ data[indextr].delta_net }} -->
            </div>
          </vs-td>
        </vs-tr>
      </template>
    </vs-table>

    <div v-if="!project" class="vx-row mb-2 mt-5 ml-1">
      <div class="vx-row ml-0">
        Total Item PO : {{number_format_qty(total_item_po)}}<br />
        Total Item Received : {{number_format_qty(total_item_received)}}<br />
        Total Item Invoice : {{number_format_qty(total_item_invoice)}}
      </div>
    </div>

    <!-- <div class="vx-row mb-2 mt-5 ml-1">
      <h5>Charges</h5>
    </div>
    <vs-table stripe border description :sst="true" :data="table.data_charges">
      <template slot="thead">
        <vs-th>Charge Name</vs-th>
        <vs-th>Value</vs-th>
        <vs-th>Tax</vs-th>
      </template>

      <template slot-scope="{ data }">
        <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
          <vs-td>
            <div class="vx-row ml-0">
              <span class="w-5/5">{{
                data[indextr].charge_name.replace(/-/g, " ")
              }}</span>
            </div>
          </vs-td>
          <vs-td>
            <div class="vx-row ml-0">
              <span class="w-5/5">{{ data[indextr].value }}</span>
            </div>
          </vs-td>
          <vs-td>
            <div class="vx-row ml-0">
              <span class="w-5/5">{{ data[indextr].tax }}</span>
            </div>
          </vs-td>
        </vs-tr>
      </template>
    </vs-table> -->

    <!-- PO -->
    <vs-divider style="width: 100%; margin-left: 0%"><h5>Summary</h5></vs-divider>
    <div class="vx-row w-5/5 mb-3 mt-6"> 
      <vs-input
        class="w-1/5 input-readonly"
        label="Item Value PO"
        name="Item Value PO"
        v-model="table.item_value_po"
        readonly
      />
      <vs-input
        class="w-1/5 input-readonly pl-1 pr-1"
        label="Charges Value PO"
        name="Charges Value PO"
        v-model="table.charges_value_po"
        readonly
      />
      <vs-input
        class="w-1/5 input-readonly pr-1"
        label="Tax Value PO"
        name="Tax Value PO"
        v-model="table.tax_value_po"
        readonly
      />
      <vs-input
        class="w-1/5 input-readonly pr-1"
        label="Discount Value PO"
        name="Discount Value PO"
        v-model="table.discount_value_po"
        readonly
      />
      <vs-input
        class="w-1/5 input-readonly"
        label="Total Invoice Value PO"
        name="Total Invoice Value PO"
        v-model="table.total_po_value"
        readonly
      />
    </div>

    <!-- received -->
    <div class="vx-row w-5/5 mb-3 mt-6">
      <vs-input
        class="w-1/5 input-readonly"
        label="Item Value Received"
        name="Item Value Received"
        v-model="table.item_value_received"
        readonly
      />
      <vs-input
        class="w-1/5 input-readonly pl-1 pr-1"
        label="Charges Value Received"
        name="Charges Value Received"
        v-model="table.charges_value_received"
        readonly
      />
      <vs-input
        class="w-1/5 input-readonly pr-1"
        label="Tax Value Received"
        name="Tax Value Received"
        v-model="table.tax_value_received"
        readonly
      />
      <vs-input
        class="w-1/5 input-readonly pr-1"
        label="Discount Value Received"
        name="Discount Value Received"
        v-model="table.discount_value_received"
        readonly
      />
      <vs-input
        class="w-1/5 input-readonly"
        label="Total Invoice Value Received"
        name="Total Invoice Value Received"
        v-model="table.total_received_value"
        readonly
      />
    </div>

    <!-- invoice -->
    <div class="vx-row w-5/5 mb-3 mt-6">
      <vs-input
        class="w-1/5 input-readonly"
        label="Item Value Inv"
        name="Item Value Inv"
        v-model="table.item_value"
        readonly
      />
      <vs-input
        class="w-1/5 input-readonly pl-1 pr-1"
        label="Charges Value Inv"
        name="Charges Value Inv"
        v-model="table.charges_value"
        readonly
      />
      <vs-input
        class="w-1/5 input-readonly pr-1"
        label="Tax Value Inv"
        name="Tax Value Inv"
        v-model="table.tax_value"
        readonly
      />
      <vs-input
        class="w-1/5 input-readonly pr-1"
        label="Discount Value Inv"
        name="Discount Value Inv"
        v-model="table.discount_value"
        readonly
      />
      <vs-input
        class="w-1/5 input-readonly"
        label="Total Invoice Value Inv"
        name="Total Invoice Value Inv"
        v-model="table.total_inv_value"
        readonly
      />
    </div>
    <vs-divider style="width: 100%; margin-left: 0%"><b>Notes</b></vs-divider>
    <template v-for="note in notes">
      <b v-bind:key="note">{{note}}
        <br>
      </b>
    </template>
    <vs-divider style="width: 100%; margin-left: 0%"
      >List Attachment</vs-divider
    >
    <div class="vx-row mb-3 mt-6 w-4/4" style="width: 100%; margin-left: 0%">
      <table class="vs-table vs-table--tbody-table">
        <template v-for="(tr, i) in table.file">
          <tr
            class="tr-values vs-table--tr tr-table-state-null selected"
            v-bind:key="tr.name"
          >
            <td class="td vs-table--td">{{ tr.doc_type }}</td>
            <td class="td vs-table--td">{{ tr.name }}</td>
            <td class="td vs-table--td">
              <vx-tooltip text="Show" v-if="tr.path != ''">
                <vs-button
                  type="line"
                  icon-pack="feather"
                  @click.stop="downloadFileAwsS3(tr.path)"
                  icon="icon-eye"
                />
              </vx-tooltip>
            </td>
            <td class="td vs-table--td">
              <template>
                <vx-tooltip text="Delete">
                  <vs-button
                    type="line"
                    icon-pack="feather"
                    @click.stop="handleDeleteAttachment(i,tr.id)"
                    icon="icon-trash"
                  />
                </vx-tooltip>
              </template>
            </td>
          </tr>
        </template>
      </table>
    </div>
  </div>
</template>
<script>
import Datepicker from "vuejs-datepicker";
import moment from "moment";

export default {
  components: {
    Datepicker,
  },
  props: {
    piId: {
      type: Number,
    },
    vendor: {
      type: String,
    },
    poCode: {
      type: String,
    },
    invCode: {
      type: String,
    },
    warehouseCode: {
      type: String,
    },
    warehouseName: {
      type: String,
    },
    createdDate: {
      type: String,
    },
    dueDate: {
      type: String,
    },
    project:{
      type: Boolean,
    }
  },
  created() {},
  data() {
    return this.initialState();
  },
  methods: {
    initialState() {
      return {
        optionPaymentTerm: [],
        optionBankName: [],
        search: "",
        offset: 0,
        limit: 10,
        status_submit: false,
        table: {
          inv_code: null,
          inv_date: null,
          payment_term: null,
          bank_name: null,
          account_name: null,
          account_number: null,
          total_inv_value: 0,
          item_value: 0,
          charges_value: 0,
          tax_value: 0,
          data: [],
          data_charges: [],
          file: [],
        },
        tax_item: [],
        net: [],
        total: [],
        taxx: [0, 0],
        notes: "",
        gr_codes: "",
        transaction_date: new Date(),
        disabledAfterToday: {
          from: new Date(), // Disable all dates after specific date
        },
      };
    },
    validate() {
      this.status_submit = true;
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.$vs.loading();
          let param = {
            pi_id: this.piId,
            transaction_date: moment(this.transaction_date).add(7, 'h'),
          };
          this.$http
            .post("/api/v1/pi/validate-invoice-nt", param)
            .then((resp) => {
              if (resp.code == 200) {
                this.handleClose();
                this.$vs.notify({
                  color: "success",
                  title: "Success",
                  text: resp.message,
                  position: "top-right",
                  iconPack: "feather",
                  icon: "icon-x-circle",
                });
              } else {
                this.$vs.notify({
                  color: "danger",
                  title: "Error",
                  text: resp.message,
                  position: "top-right",
                  iconPack: "feather",
                  icon: "icon-x-circle",
                });
              }
              this.$vs.loading.close();
            })
            .catch((error) => {
              this.$vs.loading.close();
              console.log(error);
            });
        }
      });
    },
    handleClose() {
      window.scrollTo(0, 0);
      this.$emit("close");
    },
    number_format(text){
      return text.toFixed(2).toString().replace(/,/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    number_format_qty(text){
      return text.toString().replace(/,/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    handleShowAttachment(selected) {
      if (selected.path != "") {
        this.downloadFileAwsS3(selected.path);
      }
    },
    handleDeleteAttachment(index,id) {
      this.table.file = this.table.file.filter((item, i) => i != index);
      this.deleteFile(id);
    },
    deleteFile(id) {
      this.$vs.loading()
      var form = new FormData()
      form.append('id',id)
      this.$http
        .post("/api/v1/pi/delete-file", form, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((resp) => {
          this.$vs.loading.close();
          if (resp.code == 200) {
            this.$vs.notify({
              color: "success",
              title: "Success",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          } else {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          }
        })
        .catch((error) => {
          this.$vs.loading.close();
          console.log(error);
        });
    },
    getData() {
      this.$vs.loading();
      this.$http
        .get("/api/v1/pi/vendor-invoice-line/" + this.piId)
        .then((resp) => {
          this.$vs.loading.close();
          if (resp.code == 200) {
            this.supplier_contact_name = resp.data.supplier_contact_name;
            this.supplier_phone = resp.data.supplier_phone;
            this.tr_date = moment(String(resp.data.tr_date)).format("YYYY-MM-DD") == "0001-01-01" ? "" : moment(String(new Date(resp.data.tr_date))).format("DD/MM/YYYY");
            this.tr_number = resp.data.tr_number;
            this.table.currency_code = resp.data.currency_code;
            this.table.inv_date =
              this.invCode != 0
                ? moment(String(new Date(resp.data.inv_date))).format("DD/MM/YYYY")
                : null;
            this.po_date = resp.data.po_date
            // this.po_date = moment(String(resp.data.po_date)).format("YYYY-MM-DD");
            this.gr_dates = resp.data.gr_dates;
            this.table.bank_name = resp.data.bank_name + " - " + resp.data.account_number + " " + resp.data.account_name;
            this.gr_codes = resp.data.gr_codes;
            this.notes = resp.data.supplier_invoice_notes.toString().split(";")
            this.table.payment_term_name = resp.data.payment_term_name;
            this.table.item_value = this.number_format(resp.data.item_value)
            this.table.charges_value = this.number_format(resp.data.charge_value)
            this.table.tax_value = this.number_format(resp.data.tax_value)
            this.table.discount_value = this.number_format(resp.data.total_discount)
            this.table.total_inv_value = this.number_format(resp.data.total_invoice_value)

            this.table.item_value_po = this.number_format(resp.data.item_value_po)
            this.table.charges_value_po = this.number_format(resp.data.charge_value_po)
            this.table.tax_value_po = this.number_format(resp.data.tax_value_po)
            this.table.discount_value_po = this.number_format(resp.data.discount_value_po)
            this.table.total_po_value = this.number_format(resp.data.total_po_value)

            this.table.item_value_received = this.number_format(resp.data.item_value_received)
            this.table.charges_value_received = this.number_format(resp.data.charge_value_received)
            this.table.tax_value_received = this.number_format(resp.data.tax_value_received)
            this.table.discount_value_received = this.number_format(resp.data.discount_value_received)
            this.table.total_received_value = this.number_format(resp.data.total_received_value)

            this.table.po_code = resp.data.po_code;
            this.table.vendor = resp.data.vendor;
            this.total_item_po = 0;
            this.total_item_received = 0;
            this.total_item_invoice = 0;
            let _this = this;
            resp.data.data_line.forEach(function (element, index) {
              _this.total_item_po += parseInt(element.qty_po);
              _this.total_item_received += parseInt(element.qty_received);
              _this.total_item_invoice += parseInt(element.quantity);

              let price_po_progress = element.price_po
              let price_bast_progress = element.price_po
              let net_po_progress = element.net_po * (element.progress_po/100)
              let net_bast_progress = element.net_po * (element.progress_bast/100)

              let asd = '';
              if(_this.project) {
                if(Math.abs(net_po_progress - net_bast_progress).toFixed(2) > 0 || Math.abs(price_bast_progress - price_po_progress) > 0 || Math.abs(element.progress_po - element.progress_bast).toFixed(2) > 0 || Math.abs(element.discount - element.discount_inv).toFixed(2) > 0){
                  asd = 'bg-highlight'
                }
              } else {
                if(Math.abs(element.quantity - element.qty_received) > 0 || Math.abs(element.price_po - element.price).toFixed(2) > 0 || Math.abs(element.discount - element.discount_inv).toFixed(2) > 0){
                  asd = 'bg-highlight'
                }
              }
              
              _this.table.data.push({
                progress_po: element.progress_po,
                progress_bast: element.progress_bast,
                class: asd,
                net: _this.number_format(parseFloat(element.net)),
                price: _this.number_format(element.price),
                price_po: _this.number_format(element.price_po),
                price_po_progress: _this.number_format(price_po_progress),
                price_bast_progress: _this.number_format(price_bast_progress),
                tax: _this.number_format(parseFloat(element.tax)),
                quantity: _this.number_format_qty(element.quantity),
                item_name: element.item_name,
                item_unit: element.item_unit,
                id: element.id,
                amount_uom: element.amount_uom,
                qty_received: _this.number_format_qty(element.qty_received),
                qty_po: _this.number_format_qty(element.qty_po),
                tax_rate: element.tax_rate,

                discount_po: _this.number_format(element.discount),
                discount_inv: _this.number_format(element.discount_inv),
                net_po: _this.number_format(element.net_po),
                net_po_progress: _this.number_format(net_po_progress),
                net_received: _this.number_format(element.net_received),
                net_bast_progress: _this.number_format(net_bast_progress),
                net_inv: _this.number_format(element.net_inv),
                delta_net: _this.number_format(Math.abs(element.net_inv - element.net_po)),
                delta_net_progress: _this.number_format(Math.abs(net_po_progress - net_bast_progress)),
                delta_progress: _this.number_format(Math.abs(element.progress_po - element.progress_bast)),
                delta_qty: _this.number_format(Math.abs(element.quantity - element.qty_received)),
                delta_price: _this.number_format(Math.abs(element.price_po - element.price)),
                delta_price_progress: _this.number_format(Math.abs(price_bast_progress - price_po_progress)),
                delta_discount: _this.number_format(Math.abs(element.discount - element.discount_inv))
              });
            });

            resp.data.data_charge.forEach(function (element, index) {
              _this.table.data_charges.push({
                value: _this.number_format(element.value),
                tax: _this.number_format(element.tax),
                id: element.id,
                charge_name: element.charge_name,
                tax_rate: element.tax_rate,
              });
            });
          
            resp.data.data_attachment.map((r,i) => {
              _this.table.file.push({
                id: r.id,
                name: r.name,
                path: r.path,
                doc_type: r.doc_type,
              })
            })
          } else {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          }
        })
        .catch((error) => {
          this.$vs.loading.close();
          console.log(error);
        });
    },
  },
  mounted() {
    if (this.piId) {
      this.getData();
      this.table.inv_code = this.invCode != 0 ? this.invCode : null;
    }
  },
  computed: {},
  watch: {
    piId() {
      Object.assign(this.$data, this.initialState());
      this.getData();
      this.table.inv_code = this.invCode != 0 ? this.invCode : null;
    },
  },
};
</script>
<style scoped>
.pagination {
  display: flex;
  margin: 0.25rem 0.25rem 0;
}

.pagination button {
  flex-grow: 1;
}

.pagination button:hover {
  cursor: pointer;
}
</style>